(function redirect() {
  function isRedirectionEnabled() {
    return (
      window.bbcredirection &&
      (window.bbcredirection.geo || window.bbcredirection.device)
    );
  }

  function determineDestinationPage(currentPage, user, page) {
    let destinationPage = currentPage;

    if (window.bbcredirection.geo) {
      if (page.isInternational && user.isDomestic) {
        destinationPage = currentPage.replace(/^(.+?bbc)\.com/i, '$1.co.uk');
      } else if (page.isDomestic && !user.isDomestic) {
        destinationPage = currentPage.replace(/^(.+?bbc)\.co\.uk/i, '$1.com');
      }
    }
    return destinationPage;
  }

  function shouldRedirect(destinationPage, currentPage, referrer) {
    // avoid redirecting to ourselves, or redirecting to the page we just came from
    return (
      destinationPage !== currentPage &&
      referrer !== destinationPage.toLowerCase()
    );
  }

  function init() {
    const referrer = (window.document.referrer || '').toLowerCase();
    const host = (window.location.hostname || '').toLowerCase();
    const currentPage = window.location.href || '';

    if (!isRedirectionEnabled() || referrer === currentPage.toLowerCase()) {
      return;
    }

    const page = {
      isDomestic: /(^|\.)bbc\.co\.uk$/i.test(host),
      isInternational: /(^|\.)bbc\.com$/i.test(host),
    };

    window.bbcuser.isUKCombined().then((isUKCombined) => {
      const user = {
        isDomestic: isUKCombined,
      };

      const destinationPage = determineDestinationPage(currentPage, user, page);

      if (shouldRedirect(destinationPage, currentPage, referrer)) {
        window.location.replace(destinationPage);
      }
    });
  }

  init();
})();
